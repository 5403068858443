<template>
  <div class="loan-application">
    <Container>
      <Card padding="large">
        <Margins>
          <CardHeader :header="$t('LOAN_APPLICATION')" />
          <LoanApplicationForm class="margins__triple" />
        </Margins>
      </Card>
    </Container>

    <Toast :message="$t('APPLICATION_INFO')" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Card, CardHeader, Container, Margins, Toast } from '@/components';
import { LoanApplicationForm } from '@/containers';
import { constants } from '@/mixins';

export default {
  name: 'LoanApplication',
  mixins: [constants],
  components: {
    Container,
    Card,
    CardHeader,
    Margins,
    Toast,
    LoanApplicationForm,
  },
  computed: {
    ...mapState(['loginInfo']),
  },
};
</script>
